import copy from 'copy-to-clipboard';
import { saveAs } from 'file-saver';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from './Button';

const Entity = () => {
    const [showCopyed, setShowCopyed] = useState(false);
    const entity = useSelector(state => state.entity);
    if (!entity) {
        return null;
    }
    // 下载二维码事件
    const handleDownload = async () => {
        const res = await fetch(entity.qrcode);
        const blob = await res.blob();
        saveAs(blob, `QRCode_${entity.code}.png`);
    }
    // 复制短链接事件
    const handleCopy = () => {
        // copy(entity.shortUrl);
        copy(entity.shortUrl_2);
        setShowCopyed(true);
        setTimeout(() => setShowCopyed(false), 3000);
    }
    return (
      <div className="w-full md:w-4/6 mx-auto px-5">
        <div className="flex flex-col md:flex-row items-center">
          <div className="relative w-36 h-36 md:w-48 md:h-48 mb-4 md:mb-0 md:mr-10">
            {/* 移除透明度，直接使用渐变背景。删除了 opacity-20，使渐变背景完全不透明*/}
            <div className="absolute inset-0 bg-gradient-to-br from-blue-500 to-green-500 rounded-lg"></div>
            <div className="relative w-full h-full p-2">
              <img 
                // 全渐变透明 + mix-blend-multiply
                className="w-full h-full object-contain rounded-lg" 
                src={entity.qrcode} 
                alt="QRCode"
              />
            </div>
          </div>
          <div className="flex-1 relative">
            <div className="mb-4">
              <p className="font-bold text-base md:text-xl mb-3 flex items-center">
                <span className="bg-blue-500 text-white px-2 py-1 rounded mr-2 text-xs md:text-sm">短链接</span>
                <a className="text-blue-600 hover:text-blue-800 break-all border-b-2 border-blue-200 hover:border-blue-500 transition-colors duration-300" href={entity.shortUrl} target="_blank" rel="noopener noreferrer">{entity.shortUrl_2}</a>
              </p>
              <p className="mt-2 md:mt-4 text-sm md:text-lg flex items-center">
                <span className="bg-green-500 text-white px-2 py-1 rounded mr-2 text-xs md:text-sm">原链接</span>
                <a className="text-green-600 hover:text-green-800 break-all border-b-2 border-green-200 hover:border-green-500 transition-colors duration-300" href={entity.originUrl} target="_blank" rel="noopener noreferrer">{entity.originUrl}</a>
              </p>
            </div>
            <div className="flex flex-wrap justify-center md:justify-start gap-2 md:gap-3">
              <Button label="下载二维码" onClick={handleDownload} className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-1 px-3 md:py-2 md:px-4 rounded-lg transition-colors duration-300 text-sm md:text-base" />
              <Button label="复制短链接" onClick={handleCopy} className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-3 md:py-2 md:px-4 rounded-lg transition-colors duration-300 text-sm md:text-base" />
              {showCopyed && (
                <div className="inline-flex items-center h-5 font-mono bg-green-100 text-green-700 px-2 py-1 rounded text-xs md:text-sm">
                  <svg className='w-4 h-4 md:w-5 md:h-5 mr-1 text-green-500' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 9L9.99998 16L6.99994 13M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                  已复制
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
}

export default Entity;